<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        <span>Detalle de la evaluación</span>
        <v-spacer></v-spacer>
        <v-text-field v-model="filter" label="Búscar" prepend-icon="mdi-clipboard-text-search-outline"></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-4">
          <v-col cols="6" md="6" sm="12">

            <table>
              <tr v-for="(attribute, index) in firstColumnAttributes" :key="index">
                <td class="text--secondary font-weight-bold" :title="attribute.key">
                  <div v-html="highlightMatch(attribute.displayKey)"></div>
                </td>
                <td class="text--secondary font-weight-regular" align="right">
                  <div v-if="attribute.value">
                    <span v-if="attribute.key == 'vivienda_ubicacion'" :title="attribute.key">
                      {{ fn_parseJson(attribute.value).pais }}, {{ fn_parseJson(attribute.value).departamento }}, {{
                        fn_parseJson(attribute.value).municipio }}
                    </span>
                    <span v-else-if="!fnCheckIfDatoEsTipoFecha(attribute) && !fnCheckIfDatoEsTipoFechaUTC(attribute)"
                      :title="attribute.key">
                      {{ attribute.value }}
                    </span>
                    <span v-else-if="fnCheckIfDatoEsTipoFecha(attribute)" :title="attribute.key">
                      {{ fnGetDate(attribute.value) }}
                    </span>
                    <span v-else-if="fnCheckIfDatoEsTipoFechaUTC(attribute)" :title="attribute.key">
                      {{ fnGetDateUTC(attribute.value) }}
                    </span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <table>
              <tr v-for="(attribute, index) in secondColumnAttributes" :key="index">
                <td class="text--secondary font-weight-bold" :title="attribute.key">
                  <div v-html="highlightMatch(attribute.displayKey)"></div>
                </td>
                <td class="text--secondary font-weight-regular" align="right">
                  <div v-if="attribute.value">

                    <span v-if="attribute.key == 'vivienda_ubicacion'" :title="attribute.key">
                      {{ fn_parseJson(attribute.value).municipio }}
                    </span>
                    <span v-else-if="attribute.key == 'fiador_vivienda_ubicacion'" :title="attribute.key">
                      {{ fn_parseJson(attribute.value).municipio }}
                    </span>
                    <span v-else-if="!fnCheckIfDatoEsTipoFecha(attribute) && !fnCheckIfDatoEsTipoFechaUTC(attribute)"
                      :title="attribute.key">

                      <span v-if="attribute.key == 'resultado_evaluacion_equifax_detalle'" :title="attribute.key">
                        <v-btn x-small @click="modalBuroDeudor = true">ver detalle buros</v-btn>
                      </span>
                      <span v-else-if="attribute.key == 'fiador_resultado_evaluacion_equifax_detalle'"
                        :title="attribute.key">
                        <v-btn x-small @click="modalBuroFiador = true">ver detalle buros</v-btn>
                      </span>

                      <span v-else>
                        {{ attribute.value }}
                      </span>
                    </span>
                    <span v-else-if="fnCheckIfDatoEsTipoFecha(attribute)" :title="attribute.key">
                      {{ fnGetDate(attribute.value) }}
                    </span>
                    <span v-else-if="fnCheckIfDatoEsTipoFechaUTC(attribute)" :title="attribute.key">
                      {{ fnGetDateUTC(attribute.value) }}
                    </span>

                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="modalBuroDeudor" fullscreen hide-overlay>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="modalBuroDeudor = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          deudor
          <v-spacer></v-spacer>
          <v-btn :loading="print_loading_flag" icon dark @click="printPDF('deudor')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div ref="deudor">
            <GestionBuroDeudor :detalleEvaluacion="gestion"></GestionBuroDeudor>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>

    <v-dialog v-model="modalBuroFiador" fullscreen hide-overlay>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="modalBuroFiador = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          fiador
          <v-spacer></v-spacer>
          <v-btn :loading="print_loading_flag" icon dark @click="printPDF('fiador')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div ref="fiador">
            <GestionBuroFiador :detalleEvaluacion="gestion"></GestionBuroFiador>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>

  </v-container>
</template>
<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GestionBuroDeudor from './gestion_buro_deudor.vue';
import GestionBuroFiador from './gestion_buro_fiador.vue'
import moment from 'moment';
require('moment/locale/es.js');
export default {
  components: {
    GestionBuroDeudor,
    GestionBuroFiador
  },
  props: ['gestion'],
  name: 'mainmenu',
  data() {
    return {
      print_loading_flag: false,
      modalBuroDeudor: false,
      modalBuroFiador: false,
      filter: '',
      /**atributos que representan un SALTO DE LINEA, nueva linea */
      keysToStartNewRow: [
        'id',
        'contado',
        'identificacion_extendida_en_departamento',
        'fecha_de_nacimiento',
        'flag_direccion_entrega_y_facturacion_es_la_misma',
        'telefono_de_casa',
        'sector',
        'fiador_numero_documento_identificacion',
        'flag_reingreso',
        'fiador_titular_telefono',
        'fiador_personal_1_parentesco',
        'fiador_personal_2_parentesco',
      ],
    };
  },

  methods: {
    async printPDF(tipo) {
      this.print_loading_flag = true;
      try {
        this.print_loading_flag = true;

        // Obtener el elemento completo
        const element = this.$refs[tipo];

        // Capturar el contenido
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          logging: true,
          allowTaint: true,
          windowWidth: 1500,
          windowHeight: element.scrollHeight
        });

        // Crear PDF del tamaño del contenido
        const imgWidth = 210; // Ancho fijo en mm (tamaño carta)
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pdf = new jsPDF('p', 'mm', [imgWidth + 20, imgHeight + 20]); // +20 para márgenes

        pdf.setLineWidth(0); // Set line width to 0
        pdf.setDrawColor(255, 255, 255, 0); // Set line color to transparent

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight);

        pdf.save(`reporte buro evaluacion ${this.gestion.id}.pdf`);
        this.print_loading_flag = false;

      } catch (error) {
        console.error('Error al generar PDF:', error);
        this.print_loading_flag = false;
      }
      this.print_loading_flag = false;
    },
    fn_parseJson(value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return {}; // Retorna un objeto vacío en caso de error
      }
    },
    formatLabel(label) {
      return label
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    },
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    fnCheckIfDatoEsTipoFecha(attribute) {
      let array_fecha = [
        'flag_precalificacion_completada_fecha',
        'flag_precalificacion_en_revision_fecha',
        'flag_precalificacion_con_pendientes_fecha',
        'flag_precalificacion_con_rectificacion_fecha',
        'flag_precalificacion_con_compromiso_de_pago_fecha',
        'flag_precalificacion_listo_para_aprobacion_fecha',
        'flag_precalificacion_concluidas_fecha',
        'flag_precalificacion_cancelada_fecha',
        'createdAt'
      ];

      if (array_fecha.includes(attribute.key)) {
        return true;
      } else {
        return false;
      }
    },
    fnCheckIfDatoEsTipoFechaUTC(attribute) {
      let array_fecha = [
        'fecha_de_nacimiento',
        'fecha_campania',
      ];

      if (array_fecha.includes(attribute.key)) {
        return true;
      } else {
        return false;
      }
    },
    calculateOffset(index) {
      console.log('offset: ', (index % 3) * 4);
      // Calcula el offset necesario
      // Por ejemplo, si quieres que empiece una nueva fila en una columna específica, podrías hacer algo como:
      return (index % 3) * 4; // Ajusta según el número de columnas que tengas en cada fila
    },
    shouldStartNewRow(key, index) {
      // Check if the current attribute key is in the keysToStartNewRow array
      // and it's not the first item in the list
      console.log(index);
      return this.keysToStartNewRow.includes(key);
    },
    highlightMatch(text) {
      if (!text) return '';
      if (!this.filter) return text;
      const regex = new RegExp(`(${this.filter})`, 'gi');
      return text
        .toString()
        .replace(regex, '<strong class="highlight grey lighten-3">$1</strong>');
    },
  },
  computed: {
    firstColumnAttributes() {
      const keys = Object.keys(this.mergedAttributes);
      const middleIndex = Math.ceil(keys.length / 2);
      const firstColumn = keys.slice(0, middleIndex);
      // Buscar el objeto con `key` igual a 'fiador_primer_nombre'
      const fiadorPrimerNombreAttr = Object.values(this.mergedAttributes).find(
        attr => attr.key === 'fiador_primer_nombre'
      );
      // Verificar si el valor de `fiador_primer_nombre` es null
      const isFiadorPrimerNombreNull = fiadorPrimerNombreAttr && fiadorPrimerNombreAttr.value === null;
      console.log(isFiadorPrimerNombreNull);
      return firstColumn.reduce((obj, key) => {
        // Si `fiador_primer_nombre` es null y la clave contiene "fiador", no la incluimos
        if (isFiadorPrimerNombreNull && this.mergedAttributes[key].key.includes('fiador')) {
          console.log(this.mergedAttributes[key]);
          this.mergedAttributes[key].value = 'n/a'
        }
        obj[key] = this.mergedAttributes[key];
        return obj;
      }, {});
    },
    secondColumnAttributes() {
      const keys = Object.keys(this.mergedAttributes);
      const middleIndex = Math.ceil(keys.length / 2);
      const secondColumn = keys.slice(middleIndex);

      // Buscar el objeto con `key` igual a 'fiador_primer_nombre'
      const fiadorPrimerNombreAttr = Object.values(this.mergedAttributes).find(
        attr => attr.key === 'fiador_primer_nombre'
      );
      // Verificar si el valor de `fiador_primer_nombre` es null
      const isFiadorPrimerNombreNull = fiadorPrimerNombreAttr && fiadorPrimerNombreAttr.value === null;
      console.log(isFiadorPrimerNombreNull);


      return secondColumn.reduce((obj, key) => {

        // Si `fiador_primer_nombre` es null y la clave contiene "fiador", no la incluimos
        if (isFiadorPrimerNombreNull && this.mergedAttributes[key].key.includes('fiador')) {
          console.log(this.mergedAttributes[key]);
          this.mergedAttributes[key].value = 'n/a'
        }

        obj[key] = this.mergedAttributes[key];
        return obj;
      }, {});
    },

    prioritizedAttributes() {
      /**priorizar orden de atributos */
      const prioritizedKeys = [
        'id',
        'primer_nombre',
        'segundo_nombre',
        'tercer_nombre',
        'primer_apellido',
        'segundo_apellido',
        'tercer_apellido_o_casada',
        'nit',
      ]; // Add other prioritized keys here
      return this.gestionAttributes.filter((attribute) =>
        prioritizedKeys.includes(attribute.key)
      );
    },
    remainingAttributes() {
      /**REMOVE en las gestiones restantes (no importa su orden) */
      const prioritizedValues = ['base64EncodedImage'];
      const prioritizedKeys = [
        'id',
        'primer_nombre',
        'segundo_nombre',
        'tercer_nombre',
        'primer_apellido',
        'segundo_apellido',
        'tercer_apellido_o_casada',
        'nit',
        'flag_nacionalizado_validacion_de_2_facturas',
        'numero_de_gestion',
        'nacionalidad',
        'id_pais',
        /** */
        'flag_precalificacion_incompleta',
        'flag_precalificacion_completada',
        'flag_precalificacion_en_revision',
        'flag_precalificacion_con_pendientes',
        'flag_precalificacion_con_rectificacion',
        'flag_precalificacion_con_compromiso_de_pago',
        'flag_precalificacion_listo_para_aprobacion',
        'flag_precalificacion_concluidas',
        'flag_precalificacion_cancelada',
        /** */
        'flag_precalificacion_incompleta_fecha',
        'flag_precalificacion_completada_fecha',
        'flag_precalificacion_en_revision_fecha',
        'flag_precalificacion_con_pendientes_fecha',
        'flag_precalificacion_con_rectificacion_fecha',
        'flag_precalificacion_con_compromiso_de_pago_fecha',
        'flag_precalificacion_listo_para_aprobacion_fecha',
        'flag_precalificacion_concluidas_fecha',
        'flag_precalificacion_cancelada_fecha',
        /** */
        'CREADO_POR_USUARIO_ID',
        'nombre_completo',
        'fiador_id_pais',
        'fiador_pais',
        'updatedAt',
        /** */
        'flag_contrato_completado',
        'flag_contrato_completado_fecha',
        'flag_tiene_pedido',
        'gestion_ubicacion_lat',
        'gestion_ubicacion_lng',
        'flag_precalificacion_con_compromiso_de_pago_fecha_fin',
        'flag_precalificacion_concluidas_comentario',
        'flag_precalificacion_concluidas_por_usuario_id',
        'codigo_sap',
        'flag_precalificacion_cancelada_motivo',
        'flag_precalificacion_cancelada_por_usuario',
        'flag_precalificacion_cancelada_por_usuario_id',
        'flag_precalificacion_cancelada_etapa',
        'flag_precalificacion_eliminada',
        'flag_precalificacion_eliminada_fecha',
        'flag_precalificacion_eliminada_motivo',
        'flag_precalificacion_eliminada_por_usuario',
        'flag_precalificacion_eliminada_por_usuario_id',
        'flag_precalificacion_eliminada_etapa',
        'contrato_id',
        'documento_de_identificacion_id',
        'recibo_de_servicio_id',
        'fotografia_de_casa_id',
        'flag_reingreso',
        'titular_referencia_id',
        'flag_precalificacion_concluidas_por_usuario',
        'familiar_1_referencia_id',
        'familiar_2_referencia_id',
        'personal_1_referencia_id',
        'personal_2_referencia_id',
        'fiador_gestion_ubicacion_lat',
        'fiador_gestion_ubicacion_lng',
        'fiador_documento_de_identificacion_id',
        'fiador_recibo_de_servicio_id',
        'fiador_fotografia_de_casa_id',
        'fiador_titular_referencia_id',
        'fiador_familiar_1_referencia_id',
        'fiador_familiar_2_referencia_id',
        'fiador_personal_1_referencia_id',
        'fiador_personal_2_referencia_id',
        'flag_validacion_firma_el_deudor',
        'firma_deudor_id',
        'firma_asesor_id',
        'resultado_evaluacion_buro_interno',
        'resultado_evaluacion_politicas_internas',
        'fiador_resultado_evaluacion_equifax',
        'fiador_resultado_evaluacion_equifax_score',
        'fiador_resultado_evaluacion_equifax_tiempo',
        'fiador_resultado_evaluacion_buro_interno',
        'fiador_resultado_evaluacion_politicas_internas',
        'tiempo_total_gestion',
        'resultado_evaluacion_color',
        'tiempo_rectificacion_contrato',
        'tiempo_rectificacion_documentos',
        'tiempo_rectificacion_referencias',
        'total_rectificaciones_pendientes',
        'tiempo_rectificacion_contrato_pago',
        'tiempo_rectificacion_documentos_pago',
        'tiempo_rectificacion_referencias_pago',
        'tiempoCreacionGestionVerificacion',
        'contado',
        'rectificacioneCreadasSinAtender',
        'flag_gestion_rectificaciones_completas',
        'flag_gestion_rectificaciones_completas_fecha',
        /** */
        'resultado_evaluacion_equifax_pdf_url',
        'fiador_resultado_evaluacion_equifax_pdf_url'


      ]; // Add other prioritized keys here
      return this.gestionAttributes.filter(
        (attribute) =>
          !prioritizedKeys.includes(attribute.key) &&
          !prioritizedValues.includes(attribute.value)
      );
    },
    /**
     * personalizar etiquetas, renombrar atributos
     *
     */
    keyDisplayNameMap() {
      return {
        id: 'Número de evaluación',
        dpi: 'Número de documento identificación',
        tercer_apellido_o_casada: 'Apellido de casada o tercer apellido',
        tipo_de_persona_nacionalidad: 'Tipo de persona',
        identificacion_extendida_en_departamento:
          'Identificación extendida en:',
        flag_direccion_entrega_y_facturacion_es_la_misma:
          '¿Dirección de entrega y facturación son la misma?',
        entrega_direccion: 'Dirección de entrega',
        vivienda_tipo: 'Tipo de vivienda',
        vivienda_ubicacion: 'Ubicación de la vivienda',
        cantidad_de_tiempo_en_vivienda_metrica: 'Tiempo viviendo en vivienda',
        cantidad_de_tiempo_en_vivienda_unidades: 'Tiempo viviendo en vivienda',
        flag_persona_referida_por_consejera: '¿Es referida por una consejera?',
        createdAt: 'Fecha de creación',
        flag_validacion_firma_el_deudor: '¿Firma el deudor?',
        flag_reingreso: 'Reingreso',
        // Add more mappings here if necessary
      };
    },
    gestionAttributes() {
      return Object.keys(this.gestion).map((key) => {
        return {
          key: key,
          //displayKey: this.keyDisplayNameMap[key] || key,
          displayKey:
            this.keyDisplayNameMap[key] ||
            key
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (char) => char.toUpperCase()),
          value: this.gestion[key],
        };
      });
    },
    filteredPrioritizedAttributes() {
      return this.prioritizedAttributes.filter((attribute) => {
        const attributeValue = attribute.value
          ? attribute.value.toString().toLowerCase()
          : '';
        return (
          attribute.key.toLowerCase().includes(this.filter.toLowerCase()) ||
          attribute.displayKey
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          attributeValue.includes(this.filter.toLowerCase())
        );
      });
    },
    filteredRemainingAttributes() {
      return this.remainingAttributes.filter((attribute) => {
        const attributeValue = attribute.value
          ? attribute.value.toString().toLowerCase()
          : '';
        return (
          attribute.key.toLowerCase().includes(this.filter.toLowerCase()) ||
          attribute.displayKey
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
          attributeValue.includes(this.filter.toLowerCase())
        );
      });
    },
    mergedAttributes() {

      return [...this.filteredPrioritizedAttributes, ...this.filteredRemainingAttributes];;
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: yellow;
}
</style>